import { css } from '@emotion/css';
// eslint-disable-next-line lodash/import-scope
// import _ from 'lodash';
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import uPlot from 'uplot';

import { PanelProps, DataFrameType, DataFrame } from '@grafana/data';
import { PanelDataErrorView } from '@grafana/runtime';
import { TooltipDisplayMode } from '@grafana/schema';
import {
  KeyboardPlugin,
  Portal,
  TooltipPlugin,
  UPlotConfigBuilder,
  usePanelContext,
  VizTooltipContainer,
  ZoomPlugin,
} from '@grafana/ui';
import { TimeSeries } from 'app/core/components/TimeSeries/TimeSeries';
import { config } from 'app/core/config';

import { Options } from './panelcfg.gen';
import { AnnotationEditorPlugin } from './plugins/AnnotationEditorPlugin';
import { AnnotationsPlugin } from './plugins/AnnotationsPlugin';
import { ContextMenuPlugin } from './plugins/ContextMenuPlugin';
import { ExemplarsPlugin, getVisibleLabels } from './plugins/ExemplarsPlugin';
import { OutsideRangePlugin } from './plugins/OutsideRangePlugin';
import { ThresholdControlsPlugin } from './plugins/ThresholdControlsPlugin';
import { getPrepareTimeseriesSuggestion } from './suggestions';
import { getTimezones, prepareGraphableFields, regenerateLinksSupplier } from './utils';

interface TimeSeriesPanelProps extends PanelProps<Options> {}

export const TimeSeriesPanel = (props: TimeSeriesPanelProps) => {
  const {
    data,
    timeRange,
    timeZone,
    width,
    height,
    options,
    fieldConfig,
    onChangeTimeRange,
    replaceVariables,
    id,
  } = props;

  const { sync, canAddAnnotations, onThresholdsChange, canEditThresholds, showThresholds, dataLinkPostProcessor } =
    usePanelContext();

  const frames = useMemo(() => prepareGraphableFields(data.series, config.theme2, timeRange), [data.series, timeRange]);
  const timezones = useMemo(() => getTimezones(options.timezone, timeZone), [options.timezone, timeZone]);
  const suggestions = useMemo(() => {
    if (frames?.length && frames.every((df) => df.meta?.type === DataFrameType.TimeSeriesLong)) {
      const s = getPrepareTimeseriesSuggestion(id);
      return {
        message: 'Long data must be converted to wide',
        suggestions: s ? [s] : undefined,
      };
    }
    return undefined;
  }, [frames, id]);

  if (!frames || suggestions) {
    return (
      <PanelDataErrorView
        panelId={id}
        message={suggestions?.message}
        fieldConfig={fieldConfig}
        data={data}
        needsTimeField={true}
        needsNumberField={true}
        suggestions={suggestions?.suggestions}
      />
    );
  }

  const enableAnnotationCreation = Boolean(canAddAnnotations && canAddAnnotations());

  return (
    <TimeSeries
      frames={frames}
      structureRev={data.structureRev}
      timeRange={timeRange}
      timeZone={timezones}
      width={width}
      height={height}
      legend={options.legend}
      options={options}
    >
      {(config, alignedDataFrame) => {
        if (alignedDataFrame.fields.some((f) => Boolean(f.config.links?.length))) {
          alignedDataFrame = regenerateLinksSupplier(
            alignedDataFrame,
            frames,
            replaceVariables,
            timeZone,
            dataLinkPostProcessor
          );
        }

        return (
          <>
            {fieldConfig.defaults.custom.maxPointShow && <MaxTooltipPlugin frames={frames} config={config} />}
            <KeyboardPlugin config={config} />
            <ZoomPlugin config={config} onZoom={onChangeTimeRange} withZoomY={true} />
            {options.tooltip.mode === TooltipDisplayMode.None || (
              <TooltipPlugin
                frames={frames}
                data={alignedDataFrame}
                config={config}
                mode={options.tooltip.mode}
                sortOrder={options.tooltip.sort}
                sync={sync}
                timeZone={timeZone}
              />
            )}
            {/* Renders annotation markers*/}
            {data.annotations && (
              <AnnotationsPlugin annotations={data.annotations} config={config} timeZone={timeZone} />
            )}
            {/* Enables annotations creation*/}
            {enableAnnotationCreation ? (
              <AnnotationEditorPlugin data={alignedDataFrame} timeZone={timeZone} config={config}>
                {({ startAnnotating }) => {
                  return (
                    <ContextMenuPlugin
                      data={alignedDataFrame}
                      config={config}
                      timeZone={timeZone}
                      replaceVariables={replaceVariables}
                      defaultItems={[
                        {
                          items: [
                            {
                              label: 'Add annotation',
                              ariaLabel: 'Add annotation',
                              icon: 'comment-alt',
                              onClick: (e, p) => {
                                if (!p) {
                                  return;
                                }
                                startAnnotating({ coords: p.coords });
                              },
                            },
                          ],
                        },
                      ]}
                    />
                  );
                }}
              </AnnotationEditorPlugin>
            ) : (
              <ContextMenuPlugin
                data={alignedDataFrame}
                frames={frames}
                config={config}
                timeZone={timeZone}
                replaceVariables={replaceVariables}
                defaultItems={[]}
              />
            )}
            {data.annotations && (
              <ExemplarsPlugin
                visibleSeries={getVisibleLabels(config, frames)}
                config={config}
                exemplars={data.annotations}
                timeZone={timeZone}
              />
            )}

            {((canEditThresholds && onThresholdsChange) || showThresholds) && (
              <ThresholdControlsPlugin
                config={config}
                fieldConfig={fieldConfig}
                onThresholdsChange={canEditThresholds ? onThresholdsChange : undefined}
              />
            )}

            <OutsideRangePlugin config={config} onChangeTimeRange={onChangeTimeRange} />
          </>
        );
      }}
    </TimeSeries>
  );
};

interface MaxTooltipPluginProps {
  frames: DataFrame[];
  config: UPlotConfigBuilder;
}

const MaxTooltipPlugin = ({ frames, config }: MaxTooltipPluginProps) => {
  const plotInstance = useRef<uPlot>();
  const [maxValuesPos, setMaxValuesPos] = useState<Array<{ value: number; x: number; y: number }>>([]);
  const [panelPosition, setPanelPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  useEffect(() => {
    if (!plotInstance.current) {
      return;
    }

    const u = plotInstance.current!;
    const bbob = u.bbox;

    const maxValuesPos = u.data.slice(1).map((data: (number | undefined)[], i: number) => {
      const max = Math.max(...(data.filter((v) => v !== undefined) as number[]));
      // @ts-ignore
      const maxIndex = data.findIndex((v) => v === max);
      const x = panelPosition.x + bbob.left + u.valToPos(u.data[0][maxIndex], u.series[0]!.scale!);
      const y = panelPosition.y + bbob.top + u.valToPos(max, u.series[i + 1]!.scale!);
      return { value: max, x, y };
    });

    setMaxValuesPos(maxValuesPos);
  }, [panelPosition.x, panelPosition.y, plotInstance, frames]);

  useLayoutEffect(() => {
    if (!config) {
      return;
    }

    config.addHook('init', (u) => {
      plotInstance.current = u;

      const config = { attributes: true, childList: true, subtree: true };

      const callback = () => {
        setPanelPosition({
          x: u.root.getBoundingClientRect().left,
          y: u.root.getBoundingClientRect().top,
        });
      };

      const observer = new MutationObserver(callback);

      observer.observe(u.root, config);

      window.addEventListener('scroll', callback);

      return () => {
        observer.disconnect();
        window.removeEventListener('scroll', callback);
      };
    });
  }, [config]);

  return (
    <Portal className={styles.portal}>
      {maxValuesPos.map(({ value, ...pos }, i) => (
        <React.Fragment key={i}>
          <VizTooltipContainer position={pos} offset={{ x: -((79 + 16) / 2), y: -50 }}>
            {frames[i]?.fields[1].name} {(Math.round(value * 100) / 100).toFixed(2)}
          </VizTooltipContainer>
          {/*<VizTooltipContainer key={i} position={pos} offset={{x: 0, y: 0}}>*/}
          {/*  {frames[0].fields[i+1].name} {(Math.round(frames[0].fields[i + 1].values[maxValuesIndex[i]] * 100) / 100).toFixed(2)}*/}
          {/*</VizTooltipContainer>*/}
          <div
            className={styles.dotted}
            style={{
              // top: pos.y,
              // left: pos.x,
              transform: `translate(${pos.x - 15}px, ${pos.y - 5}px)`,
            }}
          />
        </React.Fragment>
      ))}
    </Portal>
  );
};

const styles = {
  portal: css({
    zIndex: `999 !important`,
  }),
  tooltipContainer: css({
    position: 'absolute',
    transform: 'translate(-50%, -150%)',
  }),
  dotted: css({
    position: 'absolute',
    width: 10,
    height: 10,
    backgroundColor: 'yellow',
    borderRadius: '50%',
    top: 0,
    left: 0,
    transition: 'transform 0.1s ease-out',
  }),
};
